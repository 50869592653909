// src/components/HiraganaCard.js
import React from "react";

const HiraganaCard = ({ character, onClick }) => {
  return (
    <div className="hiragana-card" onClick={() => onClick(character)}>
      {character}
    </div>
  );
};

export default HiraganaCard;
