import React, { useState, useEffect } from "react";
import TankaBattle from "./TaankaBattle";

const Ranking = () => {
  const [ranking, setRanking] = useState([]);

  useEffect(() => {
    fetch("https://www.tanka-battle.com/backend/ranking.php")
      .then((response) => response.json())
      .then((data) => {
        setRanking(data); // 取得したデータを状態に保存
      })
      .catch((error) => console.error("Error fetching ranking:", error));
  }, []);

  return (
    <TankaBattle>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h4>ランキング</h4>
        {ranking.length > 0 ? (
          <ul>
            {ranking.map((tanka, index) => (
              <li key={index}>
                {tanka.content} - {tanka.votes} votes
              </li>
            ))}
          </ul>
        ) : (
          <p>ランキングデータを読み込んでいます...</p>
        )}
      </div>
    </TankaBattle>
  );
};

export default Ranking;
