import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header
      style={{
        backgroundColor: "#f8f9fa",
        padding: "10px",
        textAlign: "center",
        position: "relative", // 左上にリンクを配置するため
      }}
    >
      {/* 左上にトップページへのリンク */}
      <Link
        to="/"
        style={{
          position: "absolute",
          left: "10px",
          top: "10px",
          textDecoration: "none",
          color: "#000",
          fontSize: "18px",
        }}
      >
        ぴーまんわーるど
      </Link>
    </header>
  );
};

export default Header;
