// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./components/HomePage";
import Ranking from "./components/TankaBattle/Ranking";
import Home from "./components/TankaBattle/Home";
import SubmitTanka from "./components/TankaBattle/SubmitTanka";
import HiraganaWorld from "./components/HiraganaWorld/HiraganaWorld";
import WordAssociation from "./components/WordAssociation/Home";
import AddWord from "./components/WordAssociation/AddWord";
import ViewNetwork from "./components/WordAssociation/ViewNetwork";
import Header from "./components/Header";
import Footer from "./components/Footer";

const App = () => {
  return (
    <Router>
      {/* ヘッダー */}
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tanka-battle" element={<Home />} />
        <Route path="/tanka-battle/ranking" element={<Ranking />} />
        <Route path="/tanka-battle/submit-tanka" element={<SubmitTanka />} />
        <Route path="/hiragana-world" element={<HiraganaWorld />} />
        <Route path="/word-association" element={<WordAssociation />} />
        <Route path="/word-association/add-word" element={<AddWord />} />
        <Route
          path="/word-association/view-network"
          element={<ViewNetwork />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
