import React, { useState } from "react";
import TankaBattle from "./TaankaBattle";

const SubmitTanka = () => {
  const [newTanka, setNewTanka] = useState(""); // 短歌入力用の状態

  const [message, setMessage] = useState(""); // フィードバックメッセージの状態

  const handleSubmitTanka = () => {
    if (!newTanka.trim()) {
      setMessage("短歌を入力してください");
      return;
    }

    fetch("https://www.tanka-battle.com/backend/post_tanka.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: newTanka }),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(data.message);
        setNewTanka(""); // 投稿後、フォームをリセット
      })
      .catch((error) => {
        setMessage("エラーが発生しました。再度お試しください。");
        console.error("Error submitting tanka:", error);
      });
  };

  return (
    <TankaBattle>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h4>短歌を投稿する</h4>
        <textarea
          value={newTanka}
          onChange={(e) => setNewTanka(e.target.value)}
          placeholder="新しい短歌を入力してください"
          rows="4"
          cols="40"
        />
        <br />
        <button onClick={handleSubmitTanka}>投稿する</button>
      </div>
    </TankaBattle>
  );
};

export default SubmitTanka;
