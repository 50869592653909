import React, { useState } from "react";
import WordAssociation from "./WordAssociation";
import "./WordAssociation.css";
import axios from "axios";

const AddWord = () => {
  const [word, setWord] = useState("");
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    setWord(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!word) {
      setMessage("単語を入力してください");
      return;
    }

    try {
      const response = await axios.post(
        "https://www.tanka-battle.com/backend/add_word.php",
        new URLSearchParams({ word: word })
      );

      if (response.data.success) {
        setMessage("単語が登録されました！");
        setWord(""); // 入力フィールドをリセット
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("エラーが発生しました", error);
      setMessage("単語の登録に失敗しました");
    }
  };

  return (
    <WordAssociation>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <h2>新しい単語を登録する</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={word}
              onChange={handleInputChange}
              placeholder="新しい単語を入力"
              style={{
                padding: "10px",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            />
            <br />
            <button
              type="submit"
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              登録
            </button>
          </form>
          {message && <p style={{ marginTop: "10px" }}>{message}</p>}
        </div>
      </div>
    </WordAssociation>
  );
};

export default AddWord;
