import React, { useState, useEffect } from "react";
import TankaBattle from "./TaankaBattle";
import "./TankaBattle.css";

const Home = () => {
  const [tankaList, setTankaList] = useState([]);
  const [totalTanka, setTotalTanka] = useState(0);

  // 短歌の総数を取得
  useEffect(() => {
    fetch("https://www.tanka-battle.com/backend/get_tanka_count.php")
      .then((response) => response.json())
      .then((data) => {
        setTotalTanka(data.total);
      })
      .catch((error) => console.error("Error fetching tanka count:", error));
  }, []);

  // ランダムに2つの短歌を取得
  const fetchRandomTanka = () => {
    if (totalTanka > 1) {
      const randomIndex1 = Math.floor(Math.random() * totalTanka) + 1;
      let randomIndex2 = Math.floor(Math.random() * totalTanka) + 1;

      while (randomIndex2 === randomIndex1) {
        randomIndex2 = Math.floor(Math.random() * totalTanka) + 1;
      }

      fetchTankaById(randomIndex1, randomIndex2);
    }
  };

  // 指定したIDの短歌を取得
  const fetchTankaById = (id1, id2) => {
    fetch(
      `https://www.tanka-battle.com/backend/get_tanka.php?id1=${id1}&id2=${id2}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTankaList(data);
      })
      .catch((error) => console.error("Error fetching tanka:", error));
  };

  useEffect(() => {
    if (totalTanka > 0) {
      fetchRandomTanka();
    }
  }, [totalTanka]);

  // 投票のための関数
  const handleVote = (id) => {
    fetch("https://www.tanka-battle.com/backend/vote.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
        fetchRandomTanka();
      })
      .catch((error) => console.error("Error voting:", error));
  };

  return (
    <TankaBattle>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h4>どちらの短歌が好き？</h4>
        <h6>好きな短歌をタッチ/クリックしてください</h6>
        {tankaList.length === 2 ? (
          <div className="tanka-container">
            {tankaList.map((tanka, index) => (
              <div
                key={index}
                className="tanka-vertical"
                onClick={() => handleVote(tanka.id)}
                style={{ cursor: "pointer" }}
              >
                <p>{tanka.content}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>短歌を読み込んでいます...</p>
        )}
      </div>
    </TankaBattle>
  );
};

export default Home;
