import React, { useEffect, useState, useRef } from "react";
import WordAssociation from "./WordAssociation";
import "./WordAssociation.css";
import axios from "axios";
import { Network } from "vis-network/standalone";

const ViewNetwork = () => {
  const [data, setData] = useState(null);
  const networkRef = useRef(null);

  // 連想データを取得する関数
  const fetchAssociations = async () => {
    try {
      const response = await axios.get(
        `https://www.tanka-battle.com/backend/get_associations.php?nocache=${Date.now()}`
      ); // nocacheパラメータを追加してキャッシュを防止

      if (response.data.success) {
        const associations = response.data.data;
        const nodes = [];
        const edges = [];
        const nodeSet = new Map(); // ノードIDをキーとするMapに変更

        // ノードとエッジを作成
        associations.forEach(
          ({ word_id, word, associated_word_id, associated_word, count }) => {
            if (!nodeSet.has(word_id)) {
              nodeSet.set(word_id, {
                id: word_id,
                label: word,
                color: "lightgreen",
                connections: 0, // 接続数を初期化
              });
            }
            if (!nodeSet.has(associated_word_id)) {
              nodeSet.set(associated_word_id, {
                id: associated_word_id,
                label: associated_word,
                color: "lightgreen",
                connections: 0, // 接続数を初期化
              });
            }

            // 接続数を増やす
            nodeSet.get(word_id).connections += 1;
            nodeSet.get(associated_word_id).connections += 1;

            edges.push({
              from: word_id,
              to: associated_word_id,
              width: 1 + count * 0.5,
              color: { color: "lightblue" },
            });
          }
        );

        // ノードサイズを調整
        const nodesArray = Array.from(nodeSet.values()).map((node) => ({
          ...node,
          size: 10 + node.connections * 2, // 接続数に応じたサイズ調整
        }));

        setData({ nodes: nodesArray, edges });
      } else {
        alert("連想データの取得に失敗しました");
      }
    } catch (error) {
      console.error("サーバーとの通信エラー", error);
      alert("サーバーとの通信エラーが発生しました");
    }
  };

  useEffect(() => {
    fetchAssociations();
  }, []);

  useEffect(() => {
    if (networkRef.current && data) {
      const network = new Network(networkRef.current, data, {
        nodes: {
          shape: "dot",
          font: { size: 14 },
        },
        edges: {
          arrows: { to: { enabled: true, scaleFactor: 0.5 } },
        },
        interaction: {
          hover: true,
        },
        physics: {
          enabled: true,
          barnesHut: {
            gravitationalConstant: -8000,
            centralGravity: 0.3,
            springLength: 100,
            springConstant: 0.04,
          },
        },
      });

      network.on("click", (params) => {
        if (params.nodes.length > 0) {
          console.log(`Clicked node ${params.nodes[0]}`);
        }
        if (params.edges.length > 0) {
          const edge = data.edges.find((e) => e.id === params.edges[0]);
          console.log(`Clicked link between ${edge.from} and ${edge.to}`);
        }
      });
    }
  }, [data]);

  return (
    <WordAssociation>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <h2>単語ネットワークの可視化</h2>
          <div
            ref={networkRef}
            style={{
              width: "800px",
              height: "600px",
              border: "1px solid black",
            }}
          />
        </div>
      </div>
    </WordAssociation>
  );
};

export default ViewNetwork;
