// src/App.js
import "./HiraganaWorld.css";
import React, { useState } from "react";
import HiraganaCard from "./HiraganaCard";
import TextArea from "./TextArea";
import html2canvas from "html2canvas";

const HiraganaWorld = () => {
  const allHiragana = [
    "あ",
    "い",
    "う",
    "え",
    "お",
    "か",
    "き",
    "く",
    "け",
    "こ",
    "さ",
    "し",
    "す",
    "せ",
    "そ",
    "た",
    "ち",
    "つ",
    "て",
    "と",
    "な",
    "に",
    "ぬ",
    "ね",
    "の",
    "は",
    "ひ",
    "ふ",
    "へ",
    "ほ",
    "ま",
    "み",
    "む",
    "め",
    "も",
    "や",
    "ゆ",
    "よ",
    "ら",
    "り",
    "る",
    "れ",
    "ろ",
    "わ",
    "を",
    "ん",
    "が",
    "ぎ",
    "ぐ",
    "げ",
    "ご",
    "ざ",
    "じ",
    "ず",
    "ぜ",
    "ぞ",
    "だ",
    "ぢ",
    "づ",
    "で",
    "ど",
    "ば",
    "び",
    "ぶ",
    "べ",
    "ぼ",
    "ぱ",
    "ぴ",
    "ぷ",
    "ぺ",
    "ぽ",
    "っ",
    "ー",
  ]; // 例として10文字

  // ランダムに10個のひらがなを選び、全角スペースを追加する関数
  const getRandomHiragana = () => {
    const selectedHiragana = [...allHiragana]
      .sort(() => Math.random() - 0.5)
      .slice(0, 10);
    return [...selectedHiragana, "　", "改行"]; //
  };

  const [availableHiragana, setAvailableHiragana] = useState(getRandomHiragana);
  const [text, setText] = useState("");

  const handleCardClick = (character) => {
    setText((prevText) =>
      character === "改行" ? prevText + "\n" : prevText + character
    );
  };

  const handleReset = () => {
    setText("");
  };

  const handleDeleteLast = () => {
    setText((prevText) => prevText.slice(0, -1));
  };

  const handleNewHiraganaSet = () => {
    setAvailableHiragana(getRandomHiragana());
    setText("");
  };

  const handleSaveScreen = () => {
    // キャプチャする要素を、テキストエリアとカードを含むコンテナに変更
    const element = document.querySelector(".capture-container");
    html2canvas(element, {
      scrollX: 0,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.scrollWidth,
      windowHeight: document.documentElement.scrollHeight,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.download = "hiragana_world.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  return (
    <div className="HiraganaWorld">
      <div className="capture-container">
        <h1>ひらがな10文字しか使えない世界</h1>
        <div className="card-container">
          {availableHiragana.map((char, index) => (
            <HiraganaCard
              key={index}
              character={char}
              onClick={handleCardClick}
            />
          ))}
        </div>
        <div className="text-area-container">
          <TextArea text={text} />
        </div>
      </div>
      <div className="button-container">
        <button onClick={handleReset} className="styled-button">
          リセット
        </button>
        <button onClick={handleDeleteLast} className="styled-button">
          一文字消す
        </button>
        <button onClick={handleNewHiraganaSet} className="styled-button">
          新しいひらがなセット
        </button>
        <button onClick={handleSaveScreen} className="styled-button">
          画面を保存
        </button>
      </div>
    </div>
  );
};

export default HiraganaWorld;
