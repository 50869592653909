// src/components/HomePage.js
import React from "react";
import { Link } from "react-router-dom";
import "./HomePage.css";
import thumbnail1 from "../images/hiragana.png";
import thumbnail2 from "../images/tanka.png";
import thumbnail3 from "../images/renso.png";

const HomePage = () => {
  const apps = [
    {
      id: 1,
      name: "ひらがな10文字しか使えない世界",
      description: "ひらがな10文字だけで文章をつくる",
      imageUrl: thumbnail1, // サムネイル画像のURLをここに
      link: "/hiragana-world",
    },
    {
      id: 2,
      name: "短歌バトル",
      description: "好きな短歌に投票するサイト",
      imageUrl: thumbnail2, // サムネイル画像のURLをここに
      link: "/tanka-battle",
    },
    {
      id: 3,
      name: "単語連想ネットワーク",
      description: "言葉を連想して可視化する",
      imageUrl: thumbnail3, // サムネイル画像のURLをここに
      link: "/word-association",
    },
  ];

  return (
    <div>
      <h1>ぴーまんワールド</h1>
      <div className="app-cards">
        {apps.map((app) => (
          <Link to={app.link} key={app.id} className="app-card">
            <div className="card-content">
              <img
                src={app.imageUrl}
                alt={app.name}
                className="app-thumbnail"
              />
              <h2>{app.name}</h2>
              <p>{app.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
