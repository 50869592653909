// src/components/TankaBattle.js
import React from "react";
import { Link } from "react-router-dom";

const TankaBattle = ({ children }) => {
  return (
    <div>
      <h1>短歌バトル</h1>
      <nav>
        <ul className="menu-bar">
          <li>
            <Link to="/tanka-battle/">短歌バトル</Link>
          </li>
          <li>
            <Link to="/tanka-battle/ranking">ランキング</Link>
          </li>
          <li>
            <Link to="/tanka-battle/submit-tanka">短歌を投稿する</Link>
          </li>
        </ul>
      </nav>
      <div className="content">{children}</div>
    </div>
  );
};

export default TankaBattle;
