import React from "react";
import { Link } from "react-router-dom";

const WirdAssociation = ({ children }) => {
  return (
    <div className="page-specific">
      <h1>単語連想ネットワーク</h1>
      <nav>
        <ul>
          <li>
            <Link to="/word-association">連想入力</Link>
          </li>
          <li>
            <Link to="/word-association/add-word">新しい単語を登録</Link>
          </li>
          <li>
            <Link to="/word-association/view-network">単語ネットワーク</Link>
          </li>
        </ul>
      </nav>
      <div className="content">{children}</div>
    </div>
  );
};

export default WirdAssociation;
