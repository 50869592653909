import React, { useState, useEffect } from "react";
import WordAssociation from "./WordAssociation";
import "./WordAssociation.css";
import axios from "axios";

const Home = () => {
  const [word, setWord] = useState("");
  const [wordId, setWordId] = useState(null);
  const [assocWord, setAssocWord] = useState("");

  // ランダムな単語を取得する関数
  const fetchRandomWord = async () => {
    try {
      const response = await axios.get(
        `https://www.tanka-battle.com/backend/get_random_word.php?nocache=${Date.now()}`
      ); // nocacheパラメータを追加
      if (response.data.success) {
        setWord(response.data.word);
        setWordId(response.data.id);
      } else {
        alert("単語の取得に失敗しました");
      }
    } catch (error) {
      console.error("サーバーとの通信エラー", error);
      alert("サーバーとの通信エラーが発生しました");
    }
  };

  useEffect(() => {
    fetchRandomWord();
  }, []);

  const handleInputChange = (e) => {
    setAssocWord(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("word_id", wordId);
      formData.append("assoc_word", assocWord);

      const response = await axios.post(
        "https://www.tanka-battle.com/backend/add_association.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);

      // 成功した場合の処理
      if (response.data.success) {
        alert(response.data.message);
        fetchRandomWord(); // 新しい単語を再取得
      } else {
        alert(`エラー: ${response.data.message}`);
      }

      setAssocWord("");
    } catch (error) {
      console.error(error);
      alert("連想単語の登録に失敗しました");
    }
  };

  return (
    <WordAssociation>
      <div>
        <h2>連想入力ページ</h2>
        <p>表示された単語に連想される単語を入力してください: {word}</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={assocWord}
            onChange={handleInputChange}
            placeholder="連想単語を入力"
          />
          <button type="submit">送信</button>
        </form>
      </div>
    </WordAssociation>
  );
}

export default Home;
