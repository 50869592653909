// src/components/TextArea.js
import React from "react";

const TextArea = ({ text }) => {
  return (
    <div
      className="text-area"
      style={{
        whiteSpace: "pre-wrap", // 改行を反映するためのスタイル
        overflowY: "auto", // 縦スクロール
        overflowX: "hidden", // 横スクロールを隠す
        height: "100px", // 高さを固定
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        backgroundColor: "#fff",
        width: "300px", // 幅を固定
        minWidth: "300px", // 最小幅を設定
        maxWidth: "100%", // 最大幅を親要素に合わせる
        boxSizing: "border-box", // パディングを含めたサイズ調整
      }}
    >
      {text || (
        <span style={{ color: "#ccc" }}>ここにテキストが表示されます</span>
      )}
    </div>
  );
};

export default TextArea;
